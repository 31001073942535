import { writable, derived } from 'svelte/store';
import type { Writable } from 'svelte/store';

interface PaginationState<T> {
    items: T[];
    nextPageUrl: string | null;
    hasMore: boolean;
    loading: boolean;
    error: string | null;
}

export function createPaginationStore<T>() {
    const initialState: PaginationState<T> = {
        items: [],
        nextPageUrl: null,
        hasMore: false,
        loading: false,
        error: null
    };

    const { subscribe, set, update }: Writable<PaginationState<T>> = writable(initialState);

    return {
        subscribe,
        initialize: (items: T[], nextPageUrl: string | null, hasMore: boolean) => {
            set({
                ...initialState,
                items,
                nextPageUrl,
                hasMore
            });
        },
        append: (newItems: T[], nextPageUrl: string | null, hasMore: boolean) => {
            update(state => ({
                ...state,
                items: [...state.items, ...newItems],
                nextPageUrl,
                hasMore,
                loading: false,
                error: null
            }));
        },
        setLoading: (loading: boolean) => {
            update(state => ({ ...state, loading }));
        },
        setError: (error: string) => {
            update(state => ({ ...state, error, loading: false }));
        },
        reset: () => set(initialState)
    };
}

export const postsStore = createPaginationStore();
export const isLoading = derived(postsStore, $store => $store.loading);
export const hasMore = derived(postsStore, $store => $store.hasMore);
export const currentPosts = derived(postsStore, $store => $store.items);
